import { Box } from "@mui/material";
import areaImage from "../asstes/areaImage.webp";
import { makeStyles } from "@mui/styles";
import useMobileView from "../hooks/useMobileView";
import Article from "../components/Article";

const useStyles = makeStyles(
  {
    wrapper: {
      backgroundColor: "#efefef",
    },
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      backgroundColor: "#efefef",
      width: "65vw",
      maxWidth: "1100px",
      paddingTop: "5rem",
      margin: "0rem auto 5rem auto",
      "@media (max-width:1300px)": {
        flexDirection: "column",
        width: "auto",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "5rem",
      },
    },
    articleFirst: {
      margin: "50px 0 0 80px",
      width: "380px",
      "@media (max-width:1300px)": {
        width: "60vw",
      },
      "@media (max-width:900px)": { margin: "0", width: "80vw" },
    },
    quoat: {
      dislpay: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "60vw",
      maxWidth: "1300px",
      margin: "auto",
      textAlign: "center",
      paddingBottom: "50px",
      "@media (max-width:900px)": { width: "80vw" },
    },
    image: {
      width: "450px",
      margin: "0 0 0 2rem",
      "@media (max-width:1300px)": {
        width: "50vw",
        height: "700px",
        margin: "0 auto",
      },
      "@media (max-width:900px)": {
        width: "80vw",
        margin: "0",
        height: "unset",
      },
    },
    imageWrapper: {
      // width: "32.5vw",
    },
  },
  { index: 1 },
);

const Area = () => {
  const classes = useStyles();
  const isMobile = useMobileView();
  return (
    <div id="#area" className={classes.wrapper}>
      <Box className={classes.container}>
        <div className={classes.imageWrapper}>
          {" "}
          {!isMobile && (
            <img
              className={classes.image}
              alt="area"
              src={areaImage}
              loading="lazy"
            />
          )}{" "}
        </div>

        <div className={classes.articleFirst}>
          {" "}
          <Article
            heading={"Okolica"}
            mainTitle={"Agroturystyka w samym sercu Szwajcarii Połczyńskiej"}
            mainText={
              "Pojezierze Drawskie to malowniczy region w północno-zachodniej Polsce, charakteryzujący się unikalnym krajobrazem oraz bogactwem przyrodniczym. Rozległe kompleksy leśne są nieodzownym  elementem krajobrazu tego regionu. Lasy te są domem dla wielu gatunków roślin i zwierząt, co czyni je doskonałym miejscem do obserwacji dzikiej przyrody oraz spacerów po ścieżkach leśnych. Pojezierze słynie z licznych jezior o krystalicznie czystej wodzie, które zachwycają turystów swoim pięknem i niezwykłym urokiem."
            }
          />
        </div>

        {isMobile && (
          <img
            className={classes.image}
            alt="area"
            src={areaImage}
            loading="lazy"
          />
        )}
      </Box>
      <div className={classes.quoat}>
        <h2>
          “Natura jest nie tylko tym, co jest widoczne dla oka - obejmuje
          również wewnętrzne obrazy duszy.”
        </h2>
        <div>Edvard Munch</div>
      </div>
    </div>
  );
};

export default Area;
