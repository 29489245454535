import { Box } from "@mui/material";
import ContactForm from "./contactForm/ContactForm";
import { makeStyles } from "@mui/styles";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import SocialMediaBox from "../../components/SocialMediaBox";
import GoogleMap from "../../components/GoogleMap";

const useStyles = makeStyles(
  {
    mainWrapper: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      margin: "auto",
      borderTop: "1px solid",
      fontColor: "#4F4F4F",
      height: "auto",
      backgroundColor: "#efefef",
    },
    sectionWrapper: {
      display: "flex",
      margin: "0 auto",
      width: "65vw",
      maxWidth: "1200px",
      "@media (max-width:1300px)": { display: "block", width: "55vw" },
      "@media (max-width:900px)": { width: "80vw" },
    },

    infoWrapper: {
      margin: "3rem 5rem 0 0",
      width: "32.5vw",
      "@media (max-width:900px)": { width: "auto" },
      "& h2": {
        margin: "5px 0 10px 0",
      },
    },
    addressInfo: {
      marginTop: "60px",

      "@media (max-width:900px)": { marginTop: "25px" },
    },
    singleAddressItem: {
      display: "flex",
      margin: "50px 0",
      flexDirection: "row",
      alignItems: "center",
      color: "#4F4F4F",

      // justifyContent: "center",
      "& div": {
        marginLeft: "1rem",
        fontSize: "16px",
        fontWeight: "400",
      },

      "& svg": {
        color: "black",
      },
      "@media (max-width:900px)": { margin: "15px 0" },
    },
  },
  { index: 1 },
);

const ContactPage = () => {
  const classes = useStyles();

  const addressItem = (text: string, icon: any) => (
    <div className={classes.singleAddressItem}>
      {icon}
      <div>{text}</div>
    </div>
  );
  return (
    <Box className={classes.mainWrapper} id="#contact-us">
      <div className={classes.sectionWrapper}>
        <div className={classes.infoWrapper}>
          <h3>Kontakt</h3>
          <h2>Napisz do nas</h2>
          <div className={classes.addressInfo}>
            {addressItem(
              "Brusno 2, 78-320 Połczyn Zdrój",
              <LocationOnOutlinedIcon />,
            )}
            {addressItem("+48 888 707 706", <LocalPhoneOutlinedIcon />)}
            {addressItem("ranczo.brusno@gmail.com", <EmailOutlinedIcon />)}
            <div className={classes.singleAddressItem}>
              <ThumbUpAltOutlinedIcon />
              <SocialMediaBox />
            </div>
          </div>
        </div>
        <ContactForm />
      </div>
      <GoogleMap />
    </Box>
  );
};

export default ContactPage;
