import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import landingImage from "../asstes/landing-test.webp";
import Nav from "../components/Nav";
import useMobileView from "../hooks/useMobileView";
import logoWithName from "../asstes/logo-name.png";

const useStyles = makeStyles(
  {
    landingPage: {
      width: "100%",
      maxWidth: "100%",
      height: "100vh",
      padding: "0",
      backgroundImage: `url(${landingImage}) `,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      display: (props: any) => (props.loading ? "none" : "block"),
      "@media (max-width:900px)": {
        display: "flex",
        justifyContent: "center",
        paddingTop: "2rem",
      },
    },
    logoMobile: {
      height: "220px",
      width: "220px",
      position: "absolute",
      top: "300px",
      left: "50%",
      transform: "translate(-50%, -50%)",
      "@media (max-width:900px)": {
        top: "150px",
      },
    },
  },
  { index: 1 },
);

const LandingPage = ({ currentScreen, countImageLoaded, loading }: any) => {
  const classes = useStyles({ loading });
  const isMobile = useMobileView();

  return (
    <Box className={classes.landingPage} id="#landing-page">
      <Nav currentScreen={currentScreen} />
      {isMobile && (
        <img
          className={classes.logoMobile}
          alt="logo"
          src={logoWithName}
          onLoad={countImageLoaded}
          // onClick={() => handleClickScroll("/home")}
        />
      )}
    </Box>
  );
};

export default LandingPage;
