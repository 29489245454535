import { galleryImages } from "../asstes";
import { makeStyles } from "@mui/styles";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

const useStyles = makeStyles(
  {
    mainSection: {
      height: "100vh",
      width: "100%",
      backgroundColor: "#efefef",
      "@media (min-width:1800px)": {
        height: "70vh",
      },
      "@media (max-width:1300px)": { height: "auto", paddingBottom: "8rem" },
      "@media (max-width:900px)": { height: "auto", paddingBottom: "3rem" },
    },
    wrapper: {
      width: "60vw",
      margin: "0 auto",
      backgroundColor: "#efefef",
      "@media (max-width:900px)": { width: "auto", paddingTop: "4rem" },
      "& .splide": {
        width: "600px",
        height: "auto",
        margin: "auto",
        "@media (max-width:1300px)": { width: "660px" },
        "@media (max-width:900px)": { width: "330px", height: "300px" },
      },
      "& h3": {
        margin: "0",
      },
      "& h2": {
        margin: "0 0 1rem 0",
      },

      "& img": {
        height: "500px",
        width: "600px",
        "@media (max-width:1300px)": { width: "660px" },
        "@media (max-width:900px)": { width: "330px", height: "300px" },
      },
    },
    titleWrapper: {
      textAlign: "center",
      "@media (max-width:900px)": {
        width: "80vw",
        margin: "0 auto",
        textAlign: "unset",
      },
    },
    title: {
      margin: "0 auto 3rem auto",
      padding: "1rem 0",
    },
  },
  { index: 1 },
);

const Gallery = () => {
  const classes = useStyles();

  return (
    <div id="#gallery" className={classes.mainSection}>
      <div className={classes.wrapper}>
        <div className={classes.titleWrapper}>
          <h3>GALERIA</h3>
          <h2 className={classes.title}>
            Poniżej zdjęcia naszej bazy i okolicy
          </h2>
        </div>

        <Splide aria-label="My Favorite Images" options={{ perPage: 1 }}>
          {galleryImages.map((image, i) => (
            <SplideSlide key={i}>
              <img alt={`${i}`} src={image} loading="lazy" />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

export default Gallery;
