import { makeStyles } from "@mui/styles";

import { AttractionsImage } from "../asstes";
import {
  Bicycle,
  Billiards,
  Fishing,
  Livestock,
  Sauna,
  Walk,
  Bonfire,
  Salad,
} from "../asstes/services";
import SingleServiceItem from "../components/SingleServiceItem";

const useStyles = makeStyles(
  {
    container: {
      position: "relative",
      margin: "",
      height: "850px",
      width: "100%",
      backgroundColor: "#efefef",
      // "@media (max-width:1200px)": { height: "1050px" },
      "@media (max-width:900px)": { height: "900px" },
    },
    background: {
      position: "absolute",
      height: "450px",
      width: "100%",
      zIndex: "0",
      "@media (max-width:900px)": { height: "300px" },
    },
    servicesBox: {
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      margin: "0 auto",
      width: "100%",

      "@media (max-width:1200px)": { gridTemplateColumns: "repeat(2, 1fr)" },
    },
    mainSection: {
      position: "absolute",
      backgroundColor: "white",
      borderRadius: "40px",
      width: "65vw",
      zIndex: 50,
      top: "500px",
      maxWidth: "1300px",
      margin: "0 auto",
      height: "550px",
      display: "flex",
      flexDirection: "column",
      // bottom: "10px",
      left: "50%",
      transform: "translate(-50%, -50%)",

      "@media (max-width:1200px)": { height: "950px" },

      "@media (max-width:900px)": {
        width: "80vw",
      },
    },
    titleSection: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "1rem",
      "& h3": {
        marginBottom: "10px",
        "@media (max-width:900px)": { margin: "20px 0", textAlign: "center" },
      },
      "& h2": {
        marginTop: "0",
        "@media (max-width:900px)": { padding: "0 20px", textAlign: "center" },
      },
    },
    attractions: {},
  },
  { index: 1 },
);

const services = [
  {
    name: "BILARD",
    description: "Sala bilardowa z kominkiem",
    src: Billiards,
  },
  {
    name: "SAUNA",
    description: "Jacuzzi i sauna fińska",
    src: Sauna,
  },
  {
    name: "WĘDKARSTWO",
    description: "2 zarybione stawy",
    src: Fishing,
  },
  {
    name: "ZWIERZĘTA",
    description: "Zgroda z owcami, królikami i kurami ",
    src: Livestock,
  },
  {
    name: "ROWERY",
    description: "Rowery do urzytku gości",
    src: Bicycle,
  },
  {
    name: "SPACERY",
    description: "Licznę leśne ścieżki idealne na wędrówki.",
    src: Walk,
  },
  {
    name: "KUCHNIA",
    description: "Sezonowa i pieczołowicie przegotowana kuchnia.",
    src: Salad,
  },
  {
    name: "OGNISKO",
    description: "Miejsce na oginisko i grilla.",
    src: Bonfire,
  },
  // {
  //   name: "LAS",
  //   description: "Przepiękne lasy Szwajcari Połczyńskiej.",
  //   src: Forest,
  // },
];

const Attractions = () => {
  const classes = useStyles();

  return (
    <div id="#attractions" className={classes.container}>
      <img
        alt="backgroundImage"
        src={AttractionsImage}
        className={classes.background}
        loading="lazy"
      />
      <div className={classes.mainSection}>
        <div className={classes.titleSection}>
          <h3>ATRAKCJE</h3>
          <h2>Do dyspozycji naszych Gości</h2>
        </div>

        <div className={classes.servicesBox}>
          {services.map((service) => (
            <SingleServiceItem
              name={service.name}
              description={service.description}
              src={service.src}
              key={service.name}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Attractions;
