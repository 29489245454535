import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Link } from "@mui/material";
import Hamburger from "hamburger-react";
import useMobileView from "../hooks/useMobileView";
import logoBlack from "../asstes/logo-czarne.png";
import logoWithName from "../asstes/logo-name.png";

const useStyles = makeStyles(
  {
    nav: {
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      width: "50%",
      height: " 100px",
      margin: "auto",
      background: "transparent",
      transform: "translateY(0)",
      transition: "transform 0.3s",
      paddingTop: "10vh",
      "& a": {
        textDecoration: "none",
        fontSize: "21px",
        "& :hover": { textDecoration: "underline" },
        transition: "opacity 0.5s ease-in-out",
      },
      "@media (max-width:900px)": { display: "none" },
    },
    link: {
      margin: "auto",
      fontWeight: "400",
      color: "#ffff",
      fontSize: "18px",
      transition: "opacity 0.5s ease-in-out",
    },
    logo: {
      width: "200px",
      height: "200px",
      marginTop: "1rem",
    },
    logoMobile: {
      width: "200px",
      height: "200px",
      // marginTop: "1rem",
    },
    hamburger: {
      position: "fixed",
      zIndex: "999",
      top: "30px",
      right: "30px",
      display: "none",
      "@media (max-width:1300px)": { display: "block" },
    },
    mobileMenuLogo: {
      height: "160px",
      position: "absolute",
      top: "180px",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    mobileMenu: {
      position: "fixed",
      top: "0",
      left: "0",
      height: "100vh",
      width: "100%",
      zIndex: "888",
      backgroundColor: "#DADADA",
      transition: "500ms linear",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& a": {
        textDecoration: "none",
        margin: "1rem 0",
      },
    },
    wrapper: {
      height: "80%",
      width: "60%",
    },
    mobileMenuClosed: {
      display: "none",
    },
    mobileMenuItems: {
      display: "flex",
      textDecoration: "none",
      flexDirection: "column",
      alignItems: "center",
      height: "70vh",
      margin: "23vh auto 0 auto",
    },
    mobileLink: {
      margin: "auto 0",
      fontSize: "24px",
      color: "#000",
    },
  },
  { index: 1 },
);

export const navLinks = [
  {
    name: "O nas",
    id: "#about-us",
  },
  {
    name: "Atrakcje",
    id: "#attractions",
  },
  {
    name: "Okolica",
    id: "#area",
  },
  {
    name: "Home",
    id: "#landing-page",
    logo: logoWithName,
  },
  {
    name: "Cennik",
    id: "#pricing",
  },
  {
    name: "Galeria",
    id: "#gallery",
  },
  {
    name: "Kontakt",
    id: "#contact-us",
  },

  // { name: "language", id: "#language" },
];

const Nav = ({ currentScreen }: any) => {
  const classes = useStyles();
  const [openMobile, setOpenMobile] = useState(false);
  const isMobile = useMobileView();

  const isLandingPage = currentScreen === "#landing-page";

  useEffect(() => {
    if (openMobile) {
      document.querySelector("body")!.classList.add("noScroll");
    } else {
      document.querySelector("body")!.classList.remove("noScroll");
    }

    return () => {
      document.querySelector("body")!.classList.remove("noScroll");
    };
  }, [openMobile]);

  const handleClickScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
      setOpenMobile(false);
    }
  };
  return (
    <>
      <div className={classes.hamburger}>
        <Hamburger
          toggle={() => setOpenMobile((prev) => !prev)}
          toggled={openMobile}
          color={openMobile || !isLandingPage ? "#000" : "#ffff"}
        />
      </div>
      <Box className={classes.nav}>
        {/* //MOBILE // DESKTOP */}

        {!isMobile &&
          navLinks.map((link: { name: string; id: string; logo?: string }) => (
            <Link
              href={link.id}
              onClick={() => handleClickScroll(link.id)}
              key={link.id}
            >
              {link.logo ? (
                <img
                  className={classes.logo}
                  alt="logo"
                  src={link.logo}
                  loading="lazy"
                />
              ) : (
                <span className={classes.link}>{link.name}</span>
              )}
            </Link>
          ))}
      </Box>
      <div
        className={openMobile ? classes.mobileMenu : classes.mobileMenuClosed}
      >
        <div className={classes.wrapper}>
          <div className={classes.mobileMenuItems}>
            <img
              className={classes.mobileMenuLogo}
              alt="logo"
              src={logoBlack}
            />
            {navLinks
              .filter((item) => !item.logo)
              .map((link: { name: string; id: string; logo?: string }) => (
                <Link
                  href={link.id}
                  onClick={() => handleClickScroll(link.id)}
                  key={link.id}
                >
                  <span className={classes.mobileLink}>{link.name}</span>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
