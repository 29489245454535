import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  {
    pricing: {
      backgroundColor: "#efefef",
      display: "flex",
      justifyContent: "center",
      height: "85vh",
      fontSize: "15px",
      "@media (min-width:1800px)": {
        height: "55vh",
      },
      "@media (max-width:1300px)": { height: "auto" },
      "@media (max-width:900px)": { height: "60vh" },
    },
    pricingWrapper: {
      width: "70vw",
      backgroundColor: "#efefef",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "black",
      marginBottom: "5rem",

      "@media (max-width:900px)": { width: "80vw", paddingTop: "5rem" },
    },
    singleRow: {
      display: "flex",
      flexDirection: "row",
      // width: "45vw",
      backgroundColor: "#efefef",
      "@media (max-width:900px)": { width: "80vw" },

      "& div": {
        fontSize: "16px",
        fontWeight: "400",
      },
    },
    singleRowWhite: {
      display: "flex",
      flexDirection: "row",
      // width: "45vw",
      backgroundColor: "white",
      "@media (max-width:900px)": { width: "80vw" },
      "& div": {
        fontSize: "16px",
        fontWeight: "400",
      },
    },

    singleRowTitle: {
      borderRight: "1px solid",
      width: "25vw",
      padding: "0.7rem",
      display: "flex",
      alignItems: "center",
      "& div": {
        fontSize: "16px",
        fontWeight: "400",
      },
      "@media (max-width:900px)": { width: "50vw" },
    },
    singleRowDescription: {
      width: "20vw",
      padding: "0.7rem",
      display: "flex",
      alignItems: "center",
      "& div": {
        fontSize: "16px",
        fontWeight: "400",
      },
      "@media (max-width:900px)": { width: "30vw" },
    },
    singleRowWhiteLast: {
      display: "flex",
      flexDirection: "row",
      // width: "45vw",
      backgroundColor: "white",
      borderRadius: "0 0 25px 25px",
      "& div": {
        fontSize: "16px",
        fontWeight: "400",
      },

      "@media (max-width:900px)": { width: "80vw" },
    },
    table: {
      margin: "1rem 0",
      borderColor: "#94703",
      borderRadius: "25px",
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      "@media (max-width:900px)": { margin: "0" },
    },
    buttonWrapper: {
      width: "55vw",
      height: "4rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
    },
    quoat: {
      margin: "auto",
      textAlign: "center",
      paddingBottom: "50px",
      "@media (max-width:900px)": { width: "80vw" },
    },
    tableHeaderFirst: {
      backgroundColor: "#94703a",
      display: "flex",
      alignItems: "center",
      padding: "1rem",
      color: "white",
      fontSize: "20px",
      fontWeight: "500",
      borderRadius: "25px 25px 0 0",
    },
    tableHeader: {
      backgroundColor: "#94703a",
      display: "flex",
      alignItems: "center",
      padding: "1rem",
      color: "white",
      fontSize: "20px",
      fontWeight: "500",
    },
  },
  { index: 1 },
);

const Pricing = () => {
  const classes = useStyles();

  const createRow = (title: string, description: string, white: boolean) => {
    return (
      <div className={white ? classes.singleRowWhite : classes.singleRow}>
        <div className={classes.singleRowTitle}>{title}</div>
        <div className={classes.singleRowDescription}>{description}</div>
      </div>
    );
  };

  return (
    <div className={classes.pricing}>
      <div className={classes.pricingWrapper}>
        <div className={classes.buttonWrapper}>
          <h2>Cennik</h2>{" "}
        </div>

        <div className={classes.table}>
          <div className={classes.tableHeaderFirst}>Nocleg</div>
          {createRow("Pokój z łożkiem 2 osobowym", "260zł", true)}
          {createRow("Pokój z 2 łożkiami 1 osobowymi", "360zł", false)}
          {createRow("Dostawka", "100zł", true)}
          <div className={classes.tableHeader}>Wyżywienie</div>
          {createRow("Śniadanie", "50zł / osoba", true)}
          {createRow(
            "Obiadokolacja (zupa, drugie danie, deser)",
            "70zł / osoba",
            false,
          )}

          <div className={classes.singleRowWhiteLast}>
            <div className={classes.singleRowTitle}>
              Wyżywienie dzieci do lat 12
            </div>
            <div className={classes.singleRowDescription}>50% ceny</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
