import { Form, Formik } from "formik";
import emailjs from "@emailjs/browser";
import { Box, Checkbox, InputLabel, Snackbar, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { validationSchema } from "./validation";

type contactFormValues = {
  email: string;
  firstName: string;
  secondName: string;
  message: string;
  phoneNumber: string;
  rightsApproved: boolean;
};

const initialValues: contactFormValues = {
  email: "",
  firstName: "",
  secondName: "",
  message: "",
  phoneNumber: "",
  rightsApproved: false,
};

const useStyles = makeStyles(
  {
    mainWrapper: {
      display: "flex",
      flexDirection: "column",
      width: "35vw",
      maxWidth: "35vw",
      margin: "145px 0 0 50px",

      "@media (max-width:1300px)": {
        margin: 0,
        width: "60vw",
        maxWidth: "60vw",
      },
      "@media (max-width:900px)": { width: "80vw", maxWidth: "80vw" },
    },
    nameContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    inputField: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      margin: "0 0 10px 0",
      ".Mui-focused:after ": {
        borderBotton: "none",
      },
    },
    input: {
      height: "2rem",
      border: "1px solid black",
      backgroundColor: "#efefef",
    },
    inputName: {
      height: "2rem",
      border: "1px solid black",
      backgroundColor: "#efefef",
      padding: "1px",
      ":focused .Mui-focused": {
        "& :after": {
          borderBotton: "none",
        },
      },
    },
    textAreaInput: {
      height: "70px",
      backgroundColor: "#efefef",
      padding: "5px",
      border: "1px solid black",
    },
    buttonSend: {
      backgroundColor: "black",
      color: "white",
      height: "40px",
      width: "30%",
      cursor: "pointer",
      "& :disabled": {
        color: "red",
      },
    },
    underBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    checkboxSection: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      height: "60px",
      width: "70%",
    },
    label: {
      marginBottom: "5px",
    },
    checkboxLabel: {
      display: "flex",
      fontSize: "12px !important",
      "@media (max-width:900px)": { fontSize: "10px !important" },
    },

    error: {
      margin: "3px 0 0 0",
      color: "red",
    },
  },
  { index: 1 },
);

const ContactForm: React.FunctionComponent = () => {
  const classes = useStyles();
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
  const [openSnackbarFailure, setOpenSnackbarFailure] = useState(false);
  const serviceId = process.env.REACT_APP_EMAIL_SERVICE_ID
    ? process.env.REACT_APP_EMAIL_SERVICE_ID
    : "";

  const onSubmit = (values: any) => {
    return emailjs
      .send(serviceId, "template_g9l4bra", {
        firstName: values.name,
        email: values.email,
        message: values.message,
      })
      .then(
        () => {
          setOpenSnackbarSuccess(true);
          console.log("SUCCESS!");
        },
        (error) => {
          setOpenSnackbarFailure(true);
          console.log("FAILED...", error.text);
        },
      );
  };
  return (
    <Formik
      onSubmit={(values) => onSubmit(values)}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ values, handleSubmit, handleChange, isValid, errors }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Box className={classes.mainWrapper}>
              {/* <h2>Skotaktuj się z nami</h2> */}
              <div className={classes.nameContainer}>
                <div className={classes.inputField}>
                  <InputLabel className={classes.label}>Imię</InputLabel>
                  <input
                    name="firstName"
                    onChange={handleChange}
                    value={values.firstName}
                    type="text"
                    className={classes.inputName}
                  />
                  <div className={classes.error}>{errors.firstName}</div>
                </div>
              </div>
              <div className={classes.inputField}>
                <InputLabel className={classes.label}>Email</InputLabel>
                <input
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  className={classes.input}
                />
                <div className={classes.error}>{errors.email}</div>
              </div>
              <div className={classes.inputField}>
                <InputLabel className={classes.label}>Wiadomość</InputLabel>
                <textarea
                  name="message"
                  onChange={handleChange}
                  value={values.message}
                  className={classes.textAreaInput}
                  rows={3}
                />
                <div className={classes.error}>{errors.message}</div>
              </div>
              <div className={classes.underBox}>
                <div className={classes.checkboxSection}>
                  <Checkbox />
                  <div className={classes.checkboxLabel}>
                    Zapoznałem się w informacją o przekazywaniu danych.
                  </div>
                </div>

                <button
                  type="submit"
                  className={classes.buttonSend}
                  disabled={isValid}
                >
                  Wyślij
                </button>
              </div>
              <Snackbar
                open={openSnackbarFailure}
                onClose={() => setOpenSnackbarFailure(false)}
                autoHideDuration={4000}
                message="Note archived"
              >
                <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
                  Coś poszło nie tak, spróbuj jeszcze raz.
                </Alert>
              </Snackbar>
              <Snackbar
                open={openSnackbarSuccess}
                onClose={() => setOpenSnackbarSuccess(false)}
                autoHideDuration={4000}
                message="Note archived"
              >
                <Alert
                  severity="success"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  Email wysłany.
                </Alert>
              </Snackbar>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContactForm;
