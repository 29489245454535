import { Box, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { BookingIcon, BookingIconWhite } from "../asstes";
import useMobileView from "../hooks/useMobileView";

// const icons = [
//   { icon: FacebookIcon, url: "#facebook", name: "Facebook" },
//   { icon: InstagramIcon, url: "#url", name: "Instagram" },
//   { icon: WhatsAppIcon, url: "#url", name: "WhatsUpp" },
// ];

const useStyles = makeStyles(
  {
    iconBoxColumn: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      position: "absolute",
      height: "10rem",
      "@media (max-width:900px)": { display: "none" },

      "& img": {
        color: "white",
        height: "25px",
        width: "25px",
      },
      "& svg": {
        fontSize: "25px",
        cursor: "pointer",
        color: (props: any) => (props.isLandingPage ? "white" : "black"),

        "@media (max-width:900px)": { fontSize: "18px" },
      },
    },
    iconBoxRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      position: "relative",
      height: "2rem",
      width: "10rem",
      "& a": {
        color: "black",
      },
      "& img": {
        height: "25px",
        width: "25px",
        "@media (max-width:900px)": { width: "35px", height: "35px" },
      },
      "& svg": {
        fontSize: "25px",
        cursor: "pointer",
        "@media (max-width:900px)": { fontSize: "25px" },
      },
      "@media (max-width:900px)": { justifyContent: "space-between" },
    },
    booking: {
      height: "33px",
      width: "33px",
      cursor: "pointer",
    },
    iconWrapper: {
      "& :hover": {
        scale: "1.1",
      },
    },
  },
  { index: 1 },
);

const SocialMediaBox = ({ isColumn, currentScreen }: any) => {
  const isMobile = useMobileView();
  const classes = useStyles({
    isLandingPage: currentScreen === "#landing-page",
    isMobile: isMobile,
  });

  return (
    <Box className={isColumn ? classes.iconBoxColumn : classes.iconBoxRow}>
      <div className={classes.iconWrapper}>
        {" "}
        <Link
          href="https://facebook.com/profile.php?id=61558181206775"
          target="_blank"
          rel="noreferrer"
          color={currentScreen === "#landing-page" ? "#fff" : "#000"}
        >
          <FacebookIcon />
        </Link>
      </div>
      <div className={classes.iconWrapper}>
        <Link
          href="https://instagram.com/ranczo_brusno/"
          target="_blank"
          rel="noreferrer"
          color={currentScreen === "#landing-page" ? "#fff" : "#000"}
        >
          <InstagramIcon />
        </Link>
      </div>
      <div className={classes.iconWrapper}>
        <Link
          href="https://wa.me/15551234567"
          target="_blank"
          rel="noreferrer"
          color={currentScreen === "#landing-page" ? "#fff" : "#000"}
        >
          {" "}
          <WhatsAppIcon />
        </Link>
      </div>
      <div className={classes.iconWrapper}>
        <Link
          href="https://booking.com/Share-s5acEB/"
          target="_blank"
          rel="noreferrer"
          color={currentScreen === "#landing-page" ? "#fff" : "#000"}
        >
          <img
            className={classes.booking}
            alt="booking"
            src={
              currentScreen === "#landing-page" ? BookingIconWhite : BookingIcon
            }
          />
        </Link>
      </div>
    </Box>
  );
};

export default SocialMediaBox;
