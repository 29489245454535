import "./App.css";
import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";

import { InView } from "react-intersection-observer";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";

import { globalTheme } from "./utlis/useMaterialTheme";
import SocialMediaBox from "./components/SocialMediaBox";
import AboutUs from "./containers/AboutUs";
import useMobileView from "./hooks/useMobileView";
import NavigatorDots from "./components/NavigatorDots";
import LandingPage from "./containers/LandingPage";
import ContactPage from "./containers/ContactPage/ContactPage";
import Area from "./containers/Area";
import Footer from "./containers/Footer";
import Attractions from "./containers/Attractions";
import Gallery from "./containers/Gallery";
import Pricing from "./components/Pricing";

const useStyles = makeStyles(
  {
    mainContainer: {
      height: "100vh",
      maxWidth: "100%",
      backgroundColor: "#efefef",
    },
  },
  { index: 1 }
);

function App() {
  const [currentScreen, setCurrentScreen] = useState<string>("#landing-page");
  const classes = useStyles();
  const isMobile = useMobileView();

  const changeCurrentScreen = (id: string) => {
    setCurrentScreen(id);
  };

  useEffect(() => emailjs.init("A3s64rWPt-DYKTHuC"), []);
  return (
    <Box className={classes.mainContainer}>
      <ThemeProvider theme={globalTheme}>
        <div
          style={{
            position: "fixed",
            top: "35%",
            left: "2%",
            height: "auto",
            zIndex: 100,
          }}
        >
          {!isMobile && <NavigatorDots currentScreen={currentScreen} />}
        </div>
        <div
          style={{
            position: "fixed",
            top: "37%",
            right: "5%",
            height: "auto",
            zIndex: 100,
          }}
        >
          {!isMobile && (
            <SocialMediaBox isColumn currentScreen={currentScreen} />
          )}
        </div>
        <InView
          // threshold={0}
          as="div"
          onChange={(inView) => inView && changeCurrentScreen("#landing-page")}
        >
          <LandingPage currentScreen={currentScreen} />
        </InView>
        <InView
          threshold={0.3}
          as="div"
          onChange={(inView) => inView && changeCurrentScreen("#about-us")}
        >
          <AboutUs />
        </InView>
        <InView
          threshold={0.1}
          as="div"
          onChange={(inView) => inView && changeCurrentScreen("#attractions")}
        >
          <Attractions />
        </InView>
        <InView
          as="div"
          threshold={0.7}
          onChange={(inView) => inView && changeCurrentScreen("#area")}
        >
          <Area />
        </InView>
        <InView
          threshold={0.3}
          as="div"
          onChange={(inView) => inView && changeCurrentScreen("#pricing")}
        >
          <div id="#pricing">
            {" "}
            <Pricing />
          </div>
        </InView>
        <InView
          threshold={0.7}
          as="div"
          onChange={(inView) => inView && changeCurrentScreen("#gallery")}
        >
          <Gallery />
        </InView>
        <InView
          threshold={0.3}
          as="div"
          onChange={(inView) => inView && changeCurrentScreen("#contact-us")}
        >
          <ContactPage />
        </InView>
        {/* <OnePhotoSection /> */}

        <Footer />
        {/* <a href="https://www.mobile-calendar.com/p?partner=BN52524DC" */}
      </ThemeProvider>
    </Box>
  );
}

export default App;
