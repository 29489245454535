import AboutUsFirst from "./aboutUs-1.webp";
import AboutUsSecond from "./AboutUs-2.webp";
import AboutUsThird from "./AboutUs-3.webp";
import AboutUsFourth from "./AboutUs-4.webp";
import AttractionsImage from "./attractionsImage.webp";
import BookingIconWhite from "./booking-white.png";
import BookingIcon from "./booking.png";
import Gallery1 from "./gallery-1.webp";
import Gallery2 from "./gallery-2.webp";
import Gallery3 from "./gallery-3.webp";
import Gallery4 from "./gallery-4.webp";
import Gallery5 from "./gallery-5.webp";
import Gallery6 from "./gallery-6.webp";
import Gallery7 from "./gallery-7.webp";
import Gallery8 from "./gallery-8.webp";
import Gallery9 from "./gallery-9.webp";

export const aboutUsImages = [
  AboutUsFirst,
  AboutUsSecond,
  AboutUsThird,
  AboutUsFourth,
];

const galleryImages = [
  Gallery1,
  Gallery2,
  Gallery3,
  Gallery4,
  Gallery5,
  Gallery6,
  Gallery7,
  Gallery8,
  Gallery9,
];

export { AttractionsImage, BookingIcon, BookingIconWhite, galleryImages };
