import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import useMobileView from "../hooks/useMobileView";
import { aboutUsImages } from "../asstes";
import Article from "../components/Article";

const useStyles = makeStyles(
  {
    mainWrapper: {
      width: "100%",
      // "@media (max-width:1200px)": {
      //   display: "flex",
      //   flexDirection: "column",
      //   justifyContent: "center",
      //   alignItems: "center",
      // },
    },
    rightSectionWrapper: {
      width: "35vw",
      "@media (max-width:1300px)": { width: "80vw" },
    },
    leftSectionWrapper: {
      width: "35vw",
      "@media (max-width:1300px)": { width: "auto" },
      "@media (max-width:900px)": { width: "80vw" },
    },
    container: {
      display: "flex",
      flexDirection: "row",
      width: "70vw",
      maxWidth: "1100px",

      margin: "7rem auto 5rem auto",

      "@media (max-width:1300px)": {
        width: "80vw",
        display: "block",
        margin: "3rem auto 2rem auto",
      },
    },
    firstArticle: {
      width: "450px",
      margin: "3rem 0 0 0",
      "@media (max-width:1300px)": { margin: "0 auto", width: "60vw" },
      "@media (max-width:900px)": { margin: "0" },
    },
    firstImage: {
      margin: "3rem 0 0 0rem",
      height: "630px",
      width: "450px",

      "@media (max-width:1300px)": {
        width: "80vw",
        margin: "0",
        display: "none",
      },
    },
    imageSecond: {
      margin: "10rem 0 0 0",
      height: "400px",
      width: "450px",
      // "@media (max-width:1300px)": {
      //   height: "300px",
      //   width: "330px",
      // },
      "@media (max-width:1300px)": { width: "80vw", margin: "0" },
    },
    imageThird: {
      margin: "2rem 0 0 0",
      height: "430px",
      width: "450px",

      "@media (max-width:1300px)": {
        width: "60vw",
        margin: "0 auto",
        height: "520px",
        display: "block",
      },
      "@media (max-width:900px)": {
        width: "80vw",
        margin: "0",
        height: "320px",
        display: "block",
      },
    },
    imageFourth: {
      height: "610px",
      width: "450px",
      margin: "5rem 0 0 0",
      // "@media (max-width:1300px)": {
      //   height: "300px",
      //   width: "330px",
      // },
      "@media (max-width:1300px)": { width: "80vw", margin: "0" },
    },
  },
  { index: 1 },
);

const AboutUs: React.FC<any> = ({ countImageLoaded }: any) => {
  const classes = useStyles();
  const isMobile = useMobileView();

  return (
    <div id="#about-us" className={classes.mainWrapper}>
      <Box className={classes.container}>
        <div className={classes.leftSectionWrapper}>
          {!isMobile && (
            <img
              className={classes.firstImage}
              alt={aboutUsImages[0]}
              src={aboutUsImages[0]}
              onLoad={countImageLoaded}
            ></img>
          )}
          {!isMobile && (
            <img
              alt={aboutUsImages[2]}
              src={aboutUsImages[2]}
              className={classes.imageThird}
              onLoad={countImageLoaded}
            />
          )}
          <div className={classes.firstArticle}>
            <Article
              heading={"O nas"}
              mainTitle={"Spędź weekend blisko natury"}
              mainText={
                "W Ranczo Brusno dbamy o wyjątkowy klimat. Chcemy, aby nasi goście czuli się u nas jak w domu, dlatego stawiamy na przyjazną i serdeczną atmosferę, która sprzyja integracji oraz relaksowi. Nasz obiekt jest prowadzony przez rodzinę, co gwarantuje osobisty kontakt z właścicielami oraz indywidualne podejście do każdego gościa. Staramy się, aby każdy czuł się u nas mile widziany i doceniony."
              }
            />
          </div>

          {isMobile && (
            <img
              className={classes.imageThird}
              alt={aboutUsImages[1]}
              src={aboutUsImages[1]}
            ></img>
          )}
        </div>

        <div className={classes.rightSectionWrapper}>
          <div className={classes.firstArticle}>
            <Article
              heading={"O nas"}
              mainTitle={"Miejsce, w którym odpoczniesz od miasta"}
              mainText={
                "Zapraszamy do naszej urokliwej agroturystyki położonej na malowniczym Pojezierzu Drawskim, otoczonej dziewiczymi lasami, polami i łąkami. Nasz obiekt stanowi idealne miejsce dla wszystkich poszukujących spokoju, kontaktu z naturą oraz aktywnego wypoczynku.  Obszar agroturystki daje wiele możliwości poznawania przyrody. Bezpośrednie sąsiedztwo Drawskiego Parku Karjobrazowego pozwala na możliwość licznych wędrówek, a nasze rozlewisko to idealne miejsce na popołudniwe pływanie kajakiem czy wędkowanie."
              }
            />
          </div>

          {!isMobile && (
            <img
              alt={aboutUsImages[3]}
              src={aboutUsImages[3]}
              className={classes.imageSecond}
              onLoad={countImageLoaded}
            />
          )}
          {!isMobile && (
            <img
              alt={aboutUsImages[1]}
              src={aboutUsImages[1]}
              className={classes.imageFourth}
              onLoad={countImageLoaded}
            />
          )}
        </div>
      </Box>
    </div>
  );
};

export default AboutUs;
