import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  {
    container: {
      width: "100%",
      height: "200px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      // "@media (max-width:1200px)": { margin: "1rem 0" },
      // "@media (max-width:1100px)": {
      //   height: "150px",
      // },
    },
    description: {
      fontSize: "12px !important",
      width: "60%",
      display: "flex",
      height: "60px",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      color: "#4F4F4F",
      "@media (max-width:900px)": {
        width: "80%",
        height: "70px",
        fontSize: "12px !important",
      },
    },
    image: {
      height: "72px",
      width: "72px",

      "@media (max-width:900px)": {
        height: "62px",
        width: "62px",
      },
    },
    name: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "14px",
      fontWeight: 500,
      marginTop: "15px",
      color: "#4F4F4F",
    },
    imageWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      // height: "100px"
    },
  },
  { index: 1 },
);

const SingleServiceItem = ({ name, description, src }: any) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.imageWrapper}>
        <img className={classes.image} alt={name} src={src} loading="lazy" />
      </div>

      <div className={classes.name}>{name}</div>
      <div className={classes.description}>{description}</div>
    </div>
  );
};

export default SingleServiceItem;
