import * as React from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

const cache = createCache({
  key: "css",
  prepend: true,
});

export default function GlobalCssPriority({ children }: any) {
  return (
    <CacheProvider value={cache}>
      <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
    </CacheProvider>
  );
}
