import {
  AdvancedMarker, APIProvider, Map, MapCameraChangedEvent, MapCameraProps
} from '@vis.gl/react-google-maps';

import { makeStyles } from "@mui/styles";
import pinezka from "../asstes/pinezka.png";
import useMobileView from "../hooks/useMobileView";
import { useCallback, useState } from 'react';

const useStyles = makeStyles({
  mapWrapper: {
    width: "65vw",
    height: "40vh",
    margin: "5rem auto",
    maxWidth: "1300px",
    "@media (max-width:1300px)": { width: "55vw", margin: "3rem auto 0 auto" },
    "@media (max-width:900px)": { width: "80vw", margin: "3rem auto 0 auto" },
  },
},  { index: 1 });

const defaultProps = {
  center: {
    lat: 53.72174910308861,
    lng: 16.203362802404296,
    title: 'brusno-1'
  },
  zoom: 11,
};

const MarkerStyled = ({}: any) => (
  <div style={{height: "40px", width: "20px"}}>
    <img
      style={{ width: "30px", height: "45px", position: "absolute" }}
      alt="pinezka"
      src={pinezka}
    />
  </div>
);

export default function SimpleMap() {
  const classes = useStyles();
  const isMobile = useMobileView();
  const [cameraProps, setCameraProps] = useState<MapCameraProps>({ center: defaultProps.center, zoom: defaultProps.zoom });
  const REACT_APP_GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY ? process.env.REACT_APP_GOOGLE_MAP_KEY : ""
  const REACT_APP_GOOGLE_MAP_ID = process.env.REACT_APP_GOOGLE_MAP_ID ? process.env.REACT_APP_GOOGLE_MAP_ID : ""
  const handleCameraChange = useCallback((ev: MapCameraChangedEvent) => setCameraProps(ev.detail), []);


  return (


    <div style={{ height: `${isMobile ? "50vh" : "60vh"}`, width: "100%" }}>
      <div className={classes.mapWrapper}>
      <APIProvider apiKey={REACT_APP_GOOGLE_MAP_KEY} language={'pl'}>
                <Map
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...cameraProps}
                    mapId={REACT_APP_GOOGLE_MAP_ID}
                    style={{ height: '100%', transition: '0.3s' }}
                    gestureHandling="greedy"
                    disableDefaultUI
                    onCameraChanged={handleCameraChange}

                >

                        <AdvancedMarker               
                          position={{lat: 53.72174910308861, lng: 16.203362802404296}}   
                                              
                        >
                           <MarkerStyled><img alt="pin" src={pinezka} /></MarkerStyled> 
                        </AdvancedMarker>
            
                </Map>
            </APIProvider>
    </div>
    </div>
  );
}
