import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface ArticleProps {
  heading: string;
  mainTitle: string;
  mainText: string;
}

const useStyles = makeStyles(
  {
    article: {
      margin: "3rem 0 0 0",
      "@media (max-width:1300px)": { margin: "7rem 0" },
      "@media (max-width:900px)": { margin: "2rem 0" },
    },
    mainText: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "15px",
      color: "#4F4F4F",

      "@media (max-width:1300px)": {
        textAlign: "center",
        width: "60vw",
        margin: "0 auto",
      },

      "@media (max-width:900px)": {
        textAlign: "left ",
        width: "80vw",
        marginBottom: "20px",
      },
    },
    heading: {
      margin: "5px 0",
      "@media (max-width:1300px)": {
        textAlign: "center",
        width: "60vw",
        margin: "5px auto",
      },
      "@media (max-width:900px)": { textAlign: "left", width: "80vw" },
    },
    header: {
      margin: "10px 0 15px 0",
      "@media (max-width:1300px)": {
        textAlign: "center",
        width: "60vw",
        margin: "5px auto",
      },
      "@media (max-width:900px)": {
        textAlign: "left",
        width: "80vw",
        marginBottom: "20px",
        alignText: "center",
      },
    },
  },
  { index: 1 },
);

const Article = (props: ArticleProps) => {
  const { heading, mainTitle, mainText } = props;
  const classes = useStyles();

  return (
    <Box className={classes.article}>
      <h3 className={classes.heading}> {heading}</h3>
      <h2 className={classes.header}>{mainTitle}</h2>
      <div className={classes.mainText}>{mainText}</div>
    </Box>
  );
};

export default Article;
