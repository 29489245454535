import { makeStyles } from "@mui/styles";

import SocialMediaBox from "../components/SocialMediaBox";
import logo from "../asstes/logo.png";
import useMobileView from "../hooks/useMobileView";

const useStyles = makeStyles(
  {
    mainWrapper: {
      position: "relative",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      height: "5rem",
      backgroundColor: "#DADADA",
      maxWidth: "100%",
      "@media (max-width:900px)": { flexDirection: "column", height: "7rem" },
    },
    infoWrapper: {
      width: "20rem",
      fontSize: "11px",
      "@media (max-width:900px)": {
        width: "10rem",
        fontSize: "8px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
      },
    },
    logo: {
      width: "50px",
      height: "50px",
      position: "absolute",
      top: "50%",
      // bottom: "10px",
      transform: "translate(-50%, -50%)",
      "@media (max-width:900px)": {
        position: "relative",
        marginTop: "1rem",
        top: "unset",
        transform: "unset",
      },
    },
  },
  { index: 1 },
);

const Footer = () => {
  const classes = useStyles();
  const isMobile = useMobileView();
  return (
    <div className={classes.mainWrapper}>
      <img
        className={classes.logo}
        alt="logo"
        src={logo}
        loading="lazy"
        // onClick={() => handleClickScroll("/home")}
      />
      <div className={classes.infoWrapper}>Copyright 2023. </div>

      {!isMobile && <SocialMediaBox isColumn={false} />}
    </div>
  );
};

export default Footer;
