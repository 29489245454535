import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Podałeś za krótkie imię")
    .max(20, "Podałeś za długie imię")
    .required("To pole jest wymagane"),
  email: Yup.string()
    .email("Niepoprawny email")
    .required("To pole jest wymagane"),
  message: Yup.string()
    .min(5, "Napisałeś za krótką wiadomość")
    .max(150, "Napisałeś za długą wiadomość")
    .required("To pole jest wymagane"),
});
