import { useMemo } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  {
    mainWrapper: {
      position: "fixed",
      "@media (max-width:900px)": { display: "none" },
    },
    activeDot: {
      background: "black",
      borderRadius: "100%",
      width: "8px",
      height: "8px",
      margin: "10px",
    },
    disableDot: {
      background: "transparent",
      border: "1px solid",
      borderRadius: "100%",
      width: "6px",
      height: "6px",
      margin: "10px",
    },
    dotsWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: "black",
      textDecoration: "none",
      fontSize: "14px",
    },
  },
  { index: 1 },
);

export const navDots = [
  {
    name: "Home",
    id: "#landing-page",
  },
  {
    name: "O nas",
    id: "#about-us",
  },
  {
    name: "Atrakcje",
    id: "#attractions",
  },

  {
    name: "Okolica",
    id: "#area",
  },
  {
    name: "Cennik",
    id: "#pricing",
  },
  {
    name: "Galeria",
    id: "#gallery",
  },
  {
    name: "Kontakt",
    id: "#contact-us",
  },
  // { name: "language", id: "#language" },
];

const NavigatorDots = ({ currentScreen }: { currentScreen: string }) => {
  const classes = useStyles();

  const handleClickScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const dots = useMemo(() => {
    return navDots.map(
      (link) =>
        currentScreen !== "#landing-page" && (
          <div key={link.id} className={classes.dotsWrapper}>
            <a
              className={classes.dotsWrapper}
              href={link.id}
              onClick={() => handleClickScroll(link.id)}
            >
              <div
                className={
                  link.id === currentScreen
                    ? classes.activeDot
                    : classes.disableDot
                }
              />
              {link.id === currentScreen && <span>{link.name}</span>}
            </a>
          </div>
        ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScreen]);

  return <div className={classes.mainWrapper}>{dots}</div>;
};

export default NavigatorDots;
